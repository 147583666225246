<template>
    <div>
        <div class="w-screen h-screen bg-primary dark:bg-primaryColor">
            <div
                class="container relative flex items-end h-full pb-6 md:items-center"
            >
                <h1
                    id="head-letterize"
                    ref="headHeadline"
                    class="z-10 max-w-5xl text-6xl uppercase opacity-0 xl:pl-24 3xl:pl-0 font-display text-primary md:text-8xl lg:text-9xl"
                >
                    {{ $t('home.head.lead') }}
                </h1>
                <div
                    class="absolute inline-block w-[600px] left-16 bottom-24 md:left-16 md:bottom-48 2xl:left-44 2xl:bottom-64"
                >
                    <NuxtImg
                        class="object-cover w-full pointer-events-none brightness-50"
                        src="/images/head.webp"
                        alt=""
                    />
                </div>
                <!-- Mark -->
                <WidgetMarkComponent
                    v-if="initAnimationFinished"
                    class="absolute hidden md:-right-20 xl:right-96 md:bottom-10 xl:bottom-32 md:block"
                    :text="$t('home.head.marking')"
                />
            </div>
        </div>

        <HomeSection1 />

        <section class="container relative my-56">
            <h3
                class="top-0 hidden italic font-thin lowercase transform -rotate-90 text-featured xl:absolute -left-24 text-7xl"
            >
                featured projects
            </h3>
            <div
                class="flex flex-col gap-y-32 sm:gap-y-0 md:gap-y-24 xl:-space-y-24"
            >
                <WidgetProjectFeatured
                    v-for="(
                        project, index
                    ) in projectStore.homeFilteredProjects"
                    :id="project.title"
                    :key="project.title"
                    :index="index"
                    :name="project.title"
                    :categories="project.categories"
                    :text="project.introTxt"
                    :image="`https://content.raphaelbernhart.at/assets/${project.image}?width=512&height=740&quality=90&format=webp`"
                />
            </div>
        </section>

        <HomeSectionWhatIDo />

        <section class="relative h-[360px] sm:h-[460px] w-screen text-primary">
            <div
                data-scroll
                data-scroll-class="FADE_UP"
                data-scroll-animation-delay="250"
                class="container relative z-10 flex items-center h-full"
            >
                <h3 class="font-display text-7xl">
                    {{ $t('home.section-4.headline') }}
                </h3>
            </div>
            <NuxtImg
                src="/images/ready-for-takeoff.webp"
                class="absolute top-0 left-0 object-cover w-screen h-full brightness-75"
            />
            <!-- <img
                src="@/assets/images/ready-for-takeoff.webp"
                alt=""
                class="absolute top-0 left-0 object-cover w-screen h-full -z-10 brightness-75"
            /> -->
        </section>
    </div>
</template>

<script setup lang="ts">
// Meta
definePageMeta({
    title: 'home.meta.title',
    description: 'home.meta.description',
});

const config = useRuntimeConfig();
const projectStore = useProjectStore();
const nuxtApp = useNuxtApp();

// Refs
const headHeadline = ref();

const initAnimationFinished = false;
const initAnimation = () => {
    nuxtApp.$animateTextFadeUp(headHeadline.value, {
        blur: true,
        skew: true,
        rotate: true,
    });
};

// Computed
const isSSR = computed(() => import.meta.server);

// Methods
const fetchFilteredProjects = async () => {
    try {
        const res = await useFetch(
            `${config.public.contentApiUrl}/items/rb_portfolio_projects?limit=2&filter={"status": { "_eq": "published" }}`,
        );

        projectStore.homeFilteredProjects = res.data.value.data;
    } catch (err: any) {
        console.log(err);
        // (this as any).$sentry.captureException(err);
    }
};

// Hooks
fetchFilteredProjects();

onMounted(() => {
    initAnimation();
});

// export default defineNuxtComponent({
// head() {
//     return {
//         title: "Home",
//         meta: [
//             {
//                 hid: "description",
//                 name: "description",
//                 // content: $t("home.meta.description"),
//             },
//         ],
//     };
// },
// watch: {
//     "$store.state.transitions.initTransitionFinished"(newValue: boolean) {
//         if (newValue === true) {
//             // this.initAnimation.play();
//             // this.animateHeadImage();
//         }
//     },
// },
// mounted() {
// const letters: any = this.$letterize({
//     targets: "#head-letterize",
//     className: "inline-block",
// });
// const anime: any = (this as any).$anime;
// this.initAnimation = anime({
//     targets: letters.listAll,
//     easing: "easeInOutQuad",
//     duration: 1500,
//     translateY: [125, 0],
//     opacity: [0, 1],
//     delay(_el: any, i: number) {
//         return i * 35;
//     },
//     autoplay: false,
//     begin: () => {
//         setTimeout(() => {
//             this.initAnimationFinished = true;
//         }, 1000);
//     },
// });
// if (this.$store.state.transitions.initTransitionFinished) {
//     this.initAnimation.play();
//     this.animateHeadImage();
// }
// Update Locomotive Scroll
// setTimeout(() => {
//     this.$nuxt.$emit("update-locomotive");
// }, 350);
// },
// methods: {
//     sanitize,
// animateHeadImage() {
//     const htmlElement: HTMLElement = this.$refs.headImage;

//     // Add overflow-hidden class to parent
//     htmlElement.classList.add("overflow-hidden");

//     // Fade Up Animation
//     (this as any).$anime({
//         targets: htmlElement.childNodes[0],
//         easing: "easeInOutQuad",
//         translateY: [125, 0],
//         opacity: [0, 1],
//         duration: 1000,
//     });
// },
//     },
// });
</script>

<style class="scoped">
.featured-gap {
    row-gap: -80px;
}

.text-featured {
    transform-origin: 50% 418%;
}

.headImage img {
    opacity: 0;
}
</style>
