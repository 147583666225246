<template>
    <nuxt-link
        :to="localePath(`/works?filter=${filter}`)"
        class="whitespace-nowrap"
    >
        <div class="relative inline-block overflow-hidden text-text">
            <div
                @mouseenter="animate($event)"
                class="relative z-10 cursor-pointer"
            >
                <h3
                    class="underline no-ligature underline-offset-3 font-display text-7xl md:text-8xl xl:no-underline md:text-border-3"
                >
                    {{ text }}
                </h3>
                <h3
                    class="absolute underline bottom- no-ligature underline-offset-3 font-display text-7xl md:text-8xl xl:no-underline md:text-border-3"
                >
                    {{ text }}
                </h3>
            </div>
            <div
                class="absolute bottom-2 -z-10 hidden h-1.5 w-full bg-primary xl:block"
            ></div>
            <div
                class="absolute bottom-2 -z-10 hidden h-1.5 w-full -translate-x-full transform bg-primary xl:block"
            ></div>
        </div>
    </nuxt-link>
</template>

<script setup lang="ts">
const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    filter: {
        type: String,
        required: true,
    },
});

const localePath = useLocalePath();

// Animations
const mainTl = useGsap.timeline({
    paused: true,
});

const animate = (e: MouseEvent) => {
    const t = e.target as HTMLElement;

    mainTl.fromTo(t.nextElementSibling, { x: 0 }, { x: '100%' });
    mainTl.fromTo(
        t.nextElementSibling?.nextElementSibling,
        { x: '-100%' },
        { x: 0 },
    );

    // const tHeight = t.clientHeight
    // const wordTl = anime.timeline({
    //     targets: t,
    //     easing: 'easeOutQuad',
    //     duration: 500
    // });
    // wordTl.add({
    //     translateY: [0, `-${tHeight}px`]
    // })

    // const underline1Tl = anime.timeline({
    //     targets: t.nextElementSibling,
    //     easing: "easeInOutSine",
    //     duration: 500,
    // });
    // underline1Tl.add({
    //     // width: ['100%', '0'],
    //     translateX: [0, "100%"],
    // });
    // const underline2Tl = anime.timeline({
    //     targets: t.nextElementSibling?.nextElementSibling,
    //     easing: "easeInOutSine",
    //     duration: 500,
    // });
    // underline2Tl.add(
    //     {
    //         // width: ['0', '100%'],
    //         translateX: ["-100%", "0"],
    //     },
    //     "+=170"
    // );
};
</script>
