<template>
    <section
        ref="sectionWrapper"
        class="w-screen py-24 mt-36 bg-primary text-primary"
    >
        <div class="container">
            <div class="grid grid-cols-12 gap-16">
                <div class="col-span-12 md:col-span-5 w-min md:w-full">
                    <h3
                        ref="headline"
                        class="no-ligature font-display text-7xl"
                        v-html="sanitize($t('home.section-3.headline'))"
                    />
                </div>
                <div
                    ref="servicesWrapper"
                    class="flex flex-row flex-wrap items-center justify-between h-full col-span-12 gap-12 w-min sm:w-auto md:col-span-7 sm:flex-nowrap"
                >
                    <div class="flex flex-col gap-y-4">
                        <svg
                            class="w-12 h-12"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40.967"
                            height="40.967"
                            viewBox="0 0 40.967 40.967"
                        >
                            <g
                                id="globe-outline"
                                transform="translate(-47.5 -47.5)"
                            >
                                <path
                                    id="Pfad_29"
                                    data-name="Pfad 29"
                                    d="M67.984,48A19.984,19.984,0,1,0,87.967,67.984,19.984,19.984,0,0,0,67.984,48Z"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-miterlimit="10"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_30"
                                    data-name="Pfad 30"
                                    d="M154.155,48c-5.579,0-10.825,8.947-10.825,19.984s5.246,19.984,10.825,19.984,10.825-8.947,10.825-19.984S159.734,48,154.155,48Z"
                                    transform="translate(-86.171)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-miterlimit="10"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_31"
                                    data-name="Pfad 31"
                                    d="M117.33,117.33a23.4,23.4,0,0,0,26.645,0"
                                    transform="translate(-62.669 -62.669)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_32"
                                    data-name="Pfad 32"
                                    d="M143.975,355.494a23.4,23.4,0,0,0-26.645,0"
                                    transform="translate(-62.669 -274.188)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_33"
                                    data-name="Pfad 33"
                                    d="M256,48V87.967"
                                    transform="translate(-188.016)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-miterlimit="10"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_34"
                                    data-name="Pfad 34"
                                    d="M87.967,256H48"
                                    transform="translate(0 -188.016)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-miterlimit="10"
                                    stroke-width="1"
                                />
                            </g>
                        </svg>
                        <h4 class="text-2xl italic">
                            {{ $t('home.section-3.services.1.title') }}
                        </h4>
                        <p class="text-base max-w-[250px] sm:max-w-none">
                            {{ $t('home.section-3.services.1.text') }}
                        </p>
                    </div>
                    <div
                        class="flex flex-col gap-y-4 max-w-[250px] sm:max-w-none"
                    >
                        <svg
                            class="w-12 h-12"
                            xmlns="http://www.w3.org/2000/svg"
                            width="41.497"
                            height="24.141"
                            viewBox="0 0 41.497 24.141"
                        >
                            <g
                                id="videocam-outline"
                                transform="translate(-31.5 -127.5)"
                            >
                                <path
                                    id="Pfad_35"
                                    data-name="Pfad 35"
                                    d="M368.614,158.55l7.477,5.263a1.446,1.446,0,0,0,2.034-1.322V145.066a1.446,1.446,0,0,0-2.034-1.322l-7.477,5.263A1.446,1.446,0,0,0,368,150.19v7.177a1.446,1.446,0,0,0,.614,1.182Z"
                                    transform="translate(-305.627 -14.208)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_36"
                                    data-name="Pfad 36"
                                    d="M53.333,151.141H36.7a4.714,4.714,0,0,1-4.7-4.7V132.7a4.714,4.714,0,0,1,4.7-4.7H53.377a4.672,4.672,0,0,1,4.657,4.657v13.783A4.714,4.714,0,0,1,53.333,151.141Z"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-miterlimit="10"
                                    stroke-width="1"
                                />
                            </g>
                        </svg>
                        <h4 class="text-2xl italic">
                            {{ $t('home.section-3.services.2.title') }}
                        </h4>
                        <p class="text-base">
                            {{ $t('home.section-3.services.2.text') }}
                        </p>
                    </div>
                    <div
                        class="flex flex-col gap-y-4 max-w-[250px] sm:max-w-none"
                    >
                        <svg
                            class="w-12 h-12"
                            xmlns="http://www.w3.org/2000/svg"
                            width="41.53"
                            height="29.991"
                            viewBox="0 0 41.53 29.991"
                        >
                            <g
                                id="text-outline"
                                transform="translate(-31.356 -95)"
                            >
                                <path
                                    id="Pfad_37"
                                    data-name="Pfad 37"
                                    d="M32,124.347,42.818,95.5l10.818,28.847"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_38"
                                    data-name="Pfad 38"
                                    d="M88.063,303.5H74"
                                    transform="translate(-38.214 -189.249)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_39"
                                    data-name="Pfad 39"
                                    d="M326,195.827a7.2,7.2,0,0,1,6.671-4.327,6.91,6.91,0,0,1,7.212,7.212v12.981"
                                    transform="translate(-267.497 -87.346)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                />
                                <path
                                    id="Pfad_40"
                                    data-name="Pfad 40"
                                    d="M320,301.179a5.1,5.1,0,0,0,5.409,5.229c4.868,0,9.015-2.434,9.015-9.556V295.5a77.87,77.87,0,0,0-8.294.451C323.176,296.3,320,297.664,320,301.179Z"
                                    transform="translate(-262.037 -181.97)"
                                    fill="none"
                                    stroke="#f5f1e6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                />
                            </g>
                        </svg>

                        <h4 class="text-2xl italic">
                            {{ $t('home.section-3.services.3.title') }}
                        </h4>
                        <p class="text-base">
                            {{ $t('home.section-3.services.3.text') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { sanitize } from '@/utility/Helpers';

// Refs
const sectionWrapper = ref();
const headline = ref();
const servicesWrapper = ref();

// Animations
const initAnimations = () => {
    useNuxtApp().$animateTextFadeUp(headline.value);
    // const tl = useGsap.timeline();
    // TODO animate
    // tl.fromTo(
    //     headline.value,
    //     {
    //         x: -60,
    //         opacity: 0,
    //         duration: 2,
    //     },
    //     {
    //         x: 0,
    //         opacity: 1,
    //         duration: 2,
    //         scrollTrigger: {
    //             start: "top 75%",
    //             end: "bottom top",
    //             trigger: sectionWrapper.value,
    //             scrub: false,
    //         },
    //     }
    // );
};

onMounted(() => {
    initAnimations();
});
</script>
