<template>
    <section ref="sectionWrapper" class="container my-36">
        <div class="grid-cols-5 gap-36 space-y-28 lg:grid lg:space-y-0">
            <div class="col-span-2">
                <div class="relative">
                    <div ref="paragraphText">
                        <ParagraphComponent class="max-w-[450px]">
                            <i18n-t
                                keypath="home.section-1.intro"
                                for="home.section-1.introLinkWorkTogether"
                            >
                                <template #workSubjects>
                                    <nuxt-link to="/works" class="font-bold">
                                        {{
                                            $t(
                                                'home.section-1.introLinkWorkSubjects',
                                            )
                                        }}
                                    </nuxt-link>
                                </template>
                                <template #workTogether>
                                    <a
                                        href="mailto:mail@raphaelbernhart.at"
                                        class="font-bold"
                                    >
                                        {{
                                            $t(
                                                'home.section-1.introLinkWorkTogether',
                                            )
                                        }}
                                    </a>
                                </template>
                            </i18n-t>
                        </ParagraphComponent>
                    </div>
                    <div
                        ref="paragraphLine"
                        class="absolute right-3/4 -bottom-8 h-[1px] w-screen bg-primary lg:right-0"
                    ></div>
                </div>
            </div>
            <div ref="whatIDoWrapper" class="flex flex-col col-span-3 gap-y-1">
                <h4 class="mb-8 text-5xl italic font-thin md:text-6xl">
                    {{ $t('home.section-1.fieldsHeadline') }}
                </h4>

                <WidgetServicesLink
                    :text="developmentText"
                    filter="Web Development"
                />
                <WidgetServicesLink
                    :text="videoText"
                    filter="Video Production"
                />
                <WidgetServicesLink
                    :text="$t('home.section-1.fieldsDesign')"
                    filter="Design,Branding"
                />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
// Config
const gsap = useGsap;
const { t } = useI18n();
const { isDesktopOrTablet } = useDevice();

// Refs
const sectionWrapper = ref();
const paragraphText = ref();
const paragraphLine = ref();
const whatIDoWrapper = ref();

// Animations
const initAnimations = () => {
    let whatIDoChildren = null;
    if (whatIDoWrapper.value instanceof HTMLElement) {
        whatIDoChildren = gsap.utils.toArray(whatIDoWrapper.value.childNodes);
    }

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: sectionWrapper.value,
            start: 'top 85%',
            end: 'bottom center',
        },
    });

    tl.fromTo(
        paragraphLine.value,
        {
            width: '0vw',
        },
        {
            duration: 2,
            ease: 'power1.inOut',
            width: '50vw',
        },
    );
    tl.fromTo(
        whatIDoChildren,
        {
            opacity: 0,
            x: 40,
        },
        {
            stagger: 0.2,
            duration: 1.3,
            ease: 'power1.out',
            opacity: 1,
            x: 0,
        },
        0.3,
    );
    tl.fromTo(
        paragraphText.value,
        {
            opacity: 0,
            x: 40,
        },
        {
            stagger: 0.2,
            duration: 1.2,
            ease: 'power1.out',
            opacity: 1,
            x: 0,
        },
        0.6,
    );
};

// Computed
const developmentText = computed(() => {
    const isGreaterThanSm = useNuxtApp().$breakpoints.isGreater('sm');

    return isGreaterThanSm || isDesktopOrTablet
        ? t('home.section-1.fieldsDevelopment')
        : t('home.section-1.fieldsDevelopmentMobile');
});
const videoText = computed(() => {
    const isGreaterThanSm = useNuxtApp().$breakpoints.isGreater('sm');

    return isGreaterThanSm || isDesktopOrTablet
        ? t('home.section-1.fieldsVideo')
        : t('home.section-1.fieldsVideoMobile');
});

// Hooks
onMounted(() => {
    initAnimations();
});
</script>
